<template>
  <div id="commission-page">
    <div
      class="banner cvr-bg medium-title"
      style="background-image: url('/img/dummy/about/ocm/1.jpg');"
    >
      <div class="wrapper">
        <h1>{{ title }}</h1>
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of banner -->

    <div class="inner-sidebar">
      <div class="wrapper rowflex">
        <sidebar-about></sidebar-about>
        <article class="main wider">
          <div class="inner-waw">
            <figure v-if="banner">
              <img :src="banner" />
            </figure>
            <div class="caption">
              <div class="format-text" v-html="description"></div>
            </div>
          </div>
        </article>
        <!-- end of inner waw -->
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of inner aside -->
  </div>
</template>

<script>
import SidebarAbout from "./SidebarAbout";
export default {
  name: "Commission",
  metaInfo: {
    title: "Commission"
  },
  components: {
    SidebarAbout
  },
  data() {
    return {
      menuId: 6,
      title: null,
      banner: null,
      description: null
    };
  },
  mounted() {
    this.getContent();
  },
  methods: {
    getContent() {
      this.$axios
        .get(`/about-us/${this.menuId}`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          if (response.data.success) {
            this.title = response.data.data.title;
            this.banner = response.data.data.banner;
            this.description = response.data.data.descriptions;
          }
        });
    }
  }
};
</script>
